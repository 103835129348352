import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const Home = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (!searchParams.has('sessionId')) {
            setSearchParams({ ...searchParams, sessionId: 'test1234' });
        }
    }, [searchParams, setSearchParams]);

    // const navigateToCaptureDocsImg = () => {
    //     navigate(`/capture-docs-img?${searchParams.toString()}`);
    // };

    const navigateToUploadDocs = () => {
        navigate(`/upload-docs?${searchParams.toString()}`);
    };

    return (
        <div className="container">
            <button className="button mt-auto"
                    onClick={navigateToUploadDocs}>
                Upload Document File
            </button>
        </div>
    );
};

export default Home;

