import React from 'react';

const SubmissionCompletedPage = () => {
    return (
        <section className="section">
            <div className="container">
                <div className="notification is-primary">
                    <h1 className="title">Submission Completed</h1>
                    <p className="subtitle">Your document has been successfully submitted.</p>
                </div>
            </div>
        </section>
    );
};

export default SubmissionCompletedPage;