const statementSummarizationHost =
    process.env.REACT_APP_STATEMENT_SUMMARIZATION_HOST || 'http://localhost:8080';

const statementSummarizationEndpoint = "/bank-statement/summary"

/**
 * Send the image or file with sessiond id to our python backend
 * @param  {File|Blob} fileOrBlob  The document file or image blob to send
 * @param  {String}    sessionId   The session id of the chat
 *
 * @see     https://developer.mozilla.org/en-US/docs/Web/API/FormData/append
 */
const sendStatementForSummarize = async (fileOrBlob, sessionId) => {
    const formData = new FormData();
    formData.append("file", fileOrBlob);
    formData.append('sessionId', sessionId);
    try {
        const response = await fetch(statementSummarizationHost + statementSummarizationEndpoint, {
            method: "POST",
            headers: {
                "accept": "application/json",
            },
            body: formData,
        });

        if (!response.ok) {
            let errorMessage = 'Unknown error occurred';
            try {
                const errorData = await response.json();
                errorMessage = errorData.detail;
            } catch (jsonError) {
                // handle non-JSON error response
                if (response.status >= 500) {
                    errorMessage = 'System error occurred. Please try again later.';
                }
            }
            throw new Error(errorMessage);
        }

        return await response.json();
    } catch (error) {
        if (error instanceof TypeError) {
            throw new Error('System error occurred. Please try again later.');
        }
        console.error('Error uploading the statement:', error);
        throw error; // Re-throw the error for handling in the caller component
    }
};


export default sendStatementForSummarize;