// Upload document page

import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import "./upload-docs.css";
import sendStatementForSummarize from '../utils/summarize-statement';

const UploadDocPage = () => {
    const navigate = useNavigate();
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false); // Add loading state
    const [searchParams] = useSearchParams();
    const sessionId = searchParams.get('sessionId');

    const handleFileChange = (event) => {
        setFile(event.target.files[0]); // Update state with the selected file
    };

    const handleConfirm = async () => {
        if (!file) {
            alert("No file selected!");
            return;
        }
        setLoading(true); // Set loading to true
        try {
            const summary = await sendStatementForSummarize(file, sessionId);
            if (summary) {
                navigate('/submission-completed');
            }
        } catch (error) {
            console.error("Error during submission:", error);
            alert("Error during uploading document. Reason: " + error.message);
        } finally {
            setLoading(false); // Set loading to false after the request is done
        }
    };

    return (
        <div className="container">
            <div className="section">
                <h1 className="title">Please upload your statement document</h1>
            </div>
            <div className="section">
                <div className="file-input-container">
                    <div className={`file has-name is-boxed ${file ? 'is-success' : ''}`}>
                        <label className="file-label">
                            <input
                                className="file-input"
                                type="file"
                                name="statement-file"
                                accept="application/pdf,image/png,image/jpeg,image/gif,image/webp"
                                onChange={handleFileChange}
                            />
                            <span className="file-cta">
                                <span className="file-icon">
                                    <i className="fas fa-upload"></i>
                                </span>
                                <span className="file-label">
                                    Choose a file…
                                </span>
                            </span>
                            {file && <span className="file-name">{file.name}</span>}
                        </label>
                    </div>
                    <button className="button is-primary" onClick={handleConfirm} disabled={!file || loading}>
                        {loading ? (
                            <span className="icon is-small">
                                <i className="fas fa-spinner fa-spin"></i>
                            </span>
                        ) : (
                            'Confirm'
                        )}
                    </button>
                    {loading && (
                        <div className="loading-spinner">
                            <i className="fas fa-spinner fa-spin"></i> Uploading the given document ...
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default UploadDocPage;
// TODO: webhook to upload file to another endpoint